<template>
  <div class="row mx-0 h-100">
    <div class="col-12 card_item h-100" :class="{disabled: disabled, 'p-3': small, 'p-4': !small}">
      <div class="row mx-0 h-100 justify-content-center">
        <div class="col-12 p-0">
          <div class="row m-0 justify-content-center holder" v-if="bgimage || icon ||  title || heading || greenheading">
            <div class="bg_holder" v-if="bgimage">
              <div class="background" >
                <!-- <img :src="require('../assets/images/' + bgimage)"/> -->
              </div>
            </div>
            <div class="col-12 p-0 above">
              <div class="row m-0 justify-content-center">
                <div class="col-auto text-center p-0" v-if="icon" :class="{'mb-3': !small && (!disabled || greenheading || heading || title), 'mb-2': small && (!disabled || greenheading || heading || title)}">
                  <IconRound size="small" align="center" v-if="small">
                    <slot />
                  </IconRound>
                  <IconRound align="center" v-else>
                    <slot />
                  </IconRound>
                </div>
                <div class="col-12 text-center p-0" v-if="title" :class="{'mb-2': !small && (!disabled || greenheading || heading), 'mb-2': small && (!disabled || greenheading || heading)}">
                  <div class="text-uppercase font12">
                    {{title}}
                  </div>
                </div>
                <div class="col-12 text-center p-0" v-if="heading" :class="{'mb-3': !small && (!disabled || greenheading), 'mb-2': small && (!disabled || greenheading)}">
                  <div class="card_title">
                    {{heading}}
                  </div>
                </div>
                <div class="col-12 text-center p-0" v-if="greenheading" :class="{'mb-3': !small && (!disabled), 'mb-2': small && (!disabled)}">
                  <div class="card_heading_green">
                    {{greenheading}}
                  </div>
                </div>
                <div class="col-12 text-center p-0" v-if="!disabled">
                  <Button color="gold" btnText="Selected" icon="arrow" size="small" v-if="selected">
                    <ios-checkmark-icon class="inline-icon" />
                  </Button>
                  <Button color="grey" btnText="Select" size="small" v-else>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    IconRound: defineAsyncComponent(() => import('../components/IconRound.vue')),
    'ios-checkmark-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark.vue'))
  },
  name: 'CardSelect',
  props: ['icon', 'title', 'heading', 'content', 'footer', 'bgimage', 'image', 'greenheading', 'selected', 'disabled', 'small']
}
</script>

<style scoped>
.card_item {
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  /* box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px; */
  color: var(--normal-color);
}
.card_item.disabled {
  opacity: 0.5;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-weight: 500;
  color: var(--green-color-dark);
}
.holder {
  position: relative;
}
.above {
  z-index: 1;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.98) 0%, rgb(255, 255, 255) 100%);
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: url(../assets/images/bg.png);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-size: 50%;
  opacity: 0.02;
  height: 100%;
  width: 100%;
  z-index: 1;
}

</style>
